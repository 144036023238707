import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class SourcingModal extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   true,

            clientLst   :   [],
            clientDDL   :   [],
            positionDDL :   [],
            acctTypeDDL:  [
            ],
            areaDDL      :   [
                {"id":"1","name":"HEAD OFFICE"},
                {"id":"2","name":"LUZON"},
                {"id":"3","name":"VISAYAS"},
                {"id":"4","name":"MINDANAO"},
            ],
            aquisitionDDL:[

            ],
            accountId:"",
            areaId      :   "",
            aquisitionId:   "",
            branchId    :   "",
            clientId    :   "",
            clientName  :   "",
            positionId  :   "",
            positionName:   "",
            employeeName:   "",
            referenceNo :   "",
            DateApplied :   "",
            age         :   "",

            branchDDL:[],
            branchId:"",
            channelDDL  :    [
                {"name": 'Select Job Opening',  "id": "",},
                {"name":"BESTJOBS",             "id":"1"},
                {"name":"CLASSIFIED ADD",       "id":"2"},
                {"name":"FIELD COLLECTORS",     "id":"3"},
                {"name":"JOBSTREET",            "id":"4"},
                {"name":"OTHERS",               "id":"5"},
                {"name":"PESO",                 "id":"6"},
                {"name":"REFERRAL",             "id":"7"},
            ],
            channelId:"",
            channelName:"",

            aquisitionDDL:[],
            aquisitionlst:[],
            remarks: "",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        console.log("e")
        console.log(e)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   false,

            clientDDL   :   [],
            positionDDL :   [],
            acctTypeDDL:  [
            ],
            areaDDL      :   [
                {"id":"1","name":"HEAD OFFICE"},
                {"id":"2","name":"LUZON"},
                {"id":"3","name":"VISAYAS"},
                {"id":"4","name":"MINDANAO"},
            ],
            aquisitionDDL:[

            ],
            accountId:e.accountTypeId,
            areaId      :   this.state.userinfo.areaId,
            aquisitionId:   "",

            accountName:e.accountType,
            areaName    :   this.state.userinfo.area,
            aquisitionName:   "",

            applicationFormId:e.id,
            clientId    :   e.clientId,
            clientName  :   e.client,
            positionId  :   e.positionId,
            positionName:   e.position,
            employeeName:   e.employeeName,
            referenceNo :   e.applicationFormNo,
            dateApplied :   e.dateApplied,
            age         :   e.age,

            branchDDL:[],
            branchId:"",
            branchName:"",
            channelId   : e.channelId,
            channelName : ""
        })
        this.GetJobOpening()
        this.GetClients()
    }
    GetJobOpening = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetJobOpenings",  params)
        .then(res => {
            // const data = res.data;
            // let channelDDL = []
            // channelDDL.push({"value":"","name":"Select Job Opening"})
            // // data.jobOpenings.map(function(itm){
            // //     channelDDL.push({"value":itm.id,"name":itm.name})
            // // })
            // this.setState({channelDDL:channelDDL,channelName:data.jobOpenings.find(x=>x.id===this.state.channelId).name,isloading:false});
            const data = res.data;
            if (data.jobOpenings && Array.isArray(data.jobOpenings)) {
                let channelDDL = [];
                channelDDL.push({ "value": "", "name": "Select Job Opening" });
                data.jobOpenings.forEach(itm => {
                    channelDDL.push({ "value": itm.id, "name": itm.name });
                });
                this.setState({ channelDDL: channelDDL });
                const jobOpening = data.jobOpenings.find(x => x.id === this.state.channelId);
                this.setState({ channelName: jobOpening ? jobOpening.name : "" });
            } else {
                console.error("Invalid job openings data:", data);
            }
        })
    }
    GetClients = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({clientLst:data.clients,clientDDL:data.clients});
            this.GetClientLocation()
            console.log("clients")
            console.log(data.clients)
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetClientLocation = async() =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({branchDDL : res.data.locations ? res.data.locations : [], isloading:false})
            this.GetAccountTypes()
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    GetAccountTypes = async()=>{
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Name"          :   ""
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAccountTypes",  params)
        .then(res => {
            const data = res.data.accountTypes;
            this.setState({ acctTypeDDL : data});
            this.GetPositions()
        })
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({positionDDL:data.positions});
            this.GetAquisitionist()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetAquisitionist = async() =>{
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":"111",
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            
            if(this.state.userinfo.roleId==="35"){
                this.setState({aquisitionDDL:data.profiles.filter(x=> x.roleId==="36").filter(x=> x.areaId===this.state.userinfo.areaId && x.status==="ACTIVE" ),
                                aquisitionlst:data.profiles.filter(x=>x.status==="ACTIVE" && x.roleId==="36"),
                                isloading:false});
            }
            else{
                this.setState({aquisitionDDL:data.profiles.filter(x=> x.roleId===this.state.userinfo.roleId).filter(x=> x.areaId===this.state.userinfo.areaId && x.status==="ACTIVE" ),
                                aquisitionlst:data.profiles.filter(x=>x.status==="ACTIVE" && this.state.userinfo.roleId),
                                isloading:false});
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        //this.GetJobOpening()
    }
    handleChangedAccount = e => {
        //this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.accountId=''
            this.state.accountName=''
            this.setState({clientDDL:this.state.clientLst})
            return
        }
        this.state.accountId=e[0].id
        this.state.accountName=e[0].name
        this.setState({clientDDL : this.state.clientLst.filter(x=>x.accountTypeId===e[0].id)})
    }
    handleChangedArea = e => {
        //this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.areaId=''
            this.state.areaName=''
            this.state.aquisitionId=''
            this.state.aquisitionName=''
            this.setState({aquisitionDDL:[]})    
            return
        }
        this.state.areaId=e[0].id
        this.state.areaName=e[0].name
        console.log("aqui")
        console.log(this.state.aquisitionlst.filter(x=>x.areaId===e[0].id))
        this.setState({aquisitionDDL:this.state.aquisitionlst.filter(x=>x.areaId===e[0].id)})
    }
    handleChangedClient = e => {
        //this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId=''
            this.state.clientName=''
            this.state.accountId=''
            this.state.accountName=''
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.state.accountId=e[0].accountTypeId
        this.state.accountName=e[0].accountType
        this.GetClientLocation()
    }
    handleChangedPosition = (e) =>{
        //this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.positionId=""
            this.state.positionName=""
            return
        }
        this.state.positionId=e[0].id
        this.state.positionName=e[0].name
    }

    handleSubmit = async (StatusId) =>{
        let msg = ""
        if(StatusId==="3"){
            msg = "Are you sure you want to submit this application to talent aquisition."
        }
        if(StatusId==="10"){
            msg = "Are you sure you want to submit this application to pooling."
        }
        if(StatusId==="12"){
            msg = "Are you sure you want to submit this application to blacklist applicant."
        }
        const confirmSubmit = window.confirm(msg);
        this.setState({isloading:true,disableBtn:true})

        if (this.state.remarks.trim() === "") {
            this.setState({
                isloading: false,
                alerttype: "Error!",
                isshow: true,
                color: "danger",
                message: "Remarks field cannot be empty.",
                fade: true,
                disableBtn:false
            });
            return; 
        }

        if(StatusId==="3"){
            if(this.state.clientName==""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please select client name.",
                    fade        :   true,
                    disableBtn  :   false,
                    disableBtn:false
                })
                return 
            }
            if(this.state.positionName===""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please select position name.",
                    fade        :   true,
                    disableBtn  :   false
                })
                return 
            }
            if(this.state.branchId===""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please select Branch name.",
                    fade        :   true,
                    disableBtn  :   false
                })
                return 
            }
            if(this.state.aquisitionId===""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please select Talent Aquisition.",
                    fade        :   true,
                    disableBtn  :   false
                })
                return 
            }
        }

        if (!confirmSubmit) {
            return; 
        }
        
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationFormId":this.state.applicationFormId,
            "PositionId":this.state.positionId,
           /// "IsPooled":ispooled,
           "Remarks": this.state.remarks,

            /* "ClientId": "1",
            "IpAddress": "0.0.0.0",
            "UserId": "1",
            "ApplicationFormId": "",
            "PositionId": "" */
            "StatusId": StatusId, /* --> blacklisted - 12, process - 3  */
            "AccountId":this.state.branchAccountId,
            "AreaId":this.state.areaId,
            "AquisitionId":this.state.aquisitionId
        }

        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Pooling/SubmitPooling",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    name        :   ''
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    }


    handleModalClose = () => {
        this.props.onHide();            
    };
    handleConfirm=()=>{
        this.setState({isShow:true})
    }

    handleClose=()=>{
        this.setState({isShow:false})
    }
    handleChangeBranch = (e) =>{
        if(e.length==0)
        {
            this.setState({branchId:"",branchName:""})
            return
        }
        this.state.branchId = e[0].id
        this.state.branchName = e[0].name
    }
    handleChangeAquisition = (e) =>{
        if(e.length==0)
        {
            this.setState({aquisitionId:"",aquisitionName:""})
            return
        }
        this.state.aquisitionId = e[0].userId
        this.state.aquisitionName = e[0].employeeName
    }
    handleChangeChannel = (e) =>{
        this.setState({ channelId: e.target.value,isshow:false });
    }
    
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        RECRUITMENT >> SOURCING
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                ACCOUNT
                            </Form.Label>
                            <Col sm="5">
                                <Typeahead
                                    id="accountId"
                                    name="accountId"
                                    labelKey='name'
                                    onChange={this.handleChangedAccount}
                                    options={this.state.acctTypeDDL}
                                    selected={[this.state.accountName]}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                AREA
                            </Form.Label>
                            <Col sm="4">
                                <Typeahead
                                    id="areaId"
                                    name="areaId"
                                    labelKey='name'
                                    onChange={this.handleChangedArea}
                                    options={this.state.areaDDL}
                                    selected={[this.state.areaName]}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CLIENT
                            </Form.Label>
                            <Col sm="5">
                                <Typeahead
                                    id="clientId"
                                    name="clientId"
                                    labelKey='name'
                                    onChange={this.handleChangedClient}
                                    options={this.state.clientDDL}
                                    selected={[this.state.clientName]}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                FULL NAME
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="employeeName"
                                    name="employeeName"
                                    type="text" 
                                    value={this.state.employeeName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                POSITION
                            </Form.Label>
                            <Col sm="5">
                                <Typeahead
                                    id="positionId"
                                    name="positionId"
                                    labelKey='name'
                                    onChange={this.handleChangedPosition}
                                    options={this.state.positionDDL}
                                    disabled={this.state.disable}
                                    selected={[this.state.positionName]}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                REFERENCE NO
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="referenceNo"
                                    name="referenceNo"
                                    type="text" 
                                    value={this.state.referenceNo}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                DATE APPLIED
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control 
                                    id="dateApplied"
                                    name="dateApplied"
                                    type="text" 
                                    value={this.state.dateApplied}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                AGE
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="age"
                                    name="age"
                                    type="text" 
                                    value={this.state.age}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                BRANCH
                            </Form.Label>
                            <Col sm={5}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeBranch}
                                    options={this.state.branchDDL}
                                    placeholder="Select Branch"
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                CHANNEL
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="channelName"
                                    name="channelName"
                                    type="text" 
                                    value={this.state.channelName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                TALENT AQUISITION
                            </Form.Label>
                            <Col sm={5}>
                                <Typeahead
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangeAquisition}
                                    options={this.state.aquisitionDDL}
                                    placeholder="Select Talent Aquisition"
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                REMARKS
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control 
                                    id="remarks"
                                    name="remarks"
                                    //type="text" 
                                    value={this.state.remarks}
                                    as="textarea" rows={3}
                                    // onChange={this.handleChange}
                                    onChange={(e) => this.setState({ remarks: e.target.value })}
                                />
                            </Col>
                        </Form.Group>

                        {/* Transfer to Pooling Confirm Modal */}
                        {/* <div isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isShow ? 'display-block' : 'display-none'}>
                            <Card>
                                <Card.Body>
                                  <h5 className='no-text-transform'>Transfer to Pooling?</h5>
                                  <ButtonToolbar >
                                    
                                    <Button variant="primary" className="noser-button-mr1 ml-auto" onClick = {() => this.handleSubmit('12')} disabled={this.state.disableBtn}>confirm</Button>
                                    
                                    <Button variant="danger" className="noser-button-mr15" onClick={this.handleClose}>Close</Button>
                                </ButtonToolbar>
                                </Card.Body>
                            </Card>

                        </div> */}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = {() => this.handleSubmit('3')} disabled={this.state.disableBtn}>Proceed</Button>
                        <Button variant="warning" className="noser-button-mr1" onClick = {() => this.handleSubmit('10')} disabled={this.state.disableBtn}>pooling</Button>
                        {/* <Button variant="dark" className="noser-button-mr1" onClick = {() => this.handleSubmit('12')} disabled={this.state.disableBtn}>Blacklisted</Button> */}


                        {/* <Button variant="dark" className="noser-button-mr1" onClick = { this.handleConfirm} disabled={this.state.disableBtn}>Blacklisted</Button> */}
                        <Button variant="dark" className="noser-button-mr1" onClick = {() => this.handleSubmit('12')} disabled={this.state.disableBtn}>Blacklisted</Button>

                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
                
        </Modal>
        );
    }
}
export default SourcingModal